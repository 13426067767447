<template>
  <div>
    <header>
      <div style="margin: 0 auto;">
        <div class="toolTitle">
          <router-link to="/" tag="span">工具箱</router-link>
        </div>
      </div>
    </header>
    <div class="registerContent">
      <div class="registerTitle">
        欢迎注册！
      </div>
      <div class="registerQuestion">
        已有账号？<span style="color: #5d7cff; cursor: pointer" @click="login">登录</span>
      </div>
      <el-form :rules="registerFormRules" ref="registerForm" :model="registerForm" label-position="right" show-message>
        <el-form-item label-width="0" prop="memberPhone" style="margin-top: 20px">
          <div style="font-weight: bold">用户电话</div>
          <el-input v-model="registerForm.memberPhone" placeholder="请输入手机号" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label-width="0" prop="password" style="margin-top: 20px">
          <div style="font-weight: bold">密码</div>
          <el-input v-model="registerForm.password" placeholder="请输入密码" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item prop="sendcode" class="code" label-width="0">
          <div style="font-weight: bold">验证码</div>
          <div style="display: flex">
            <el-input v-model="registerForm.sendcode" placeholder="请输入验证码"></el-input>
            <el-button style="margin-left: 5px" type="button" @click="sendcode" :disabled="disabled"
              v-if="disabled == false">发送验证码
            </el-button>
            <el-button style="margin-left: 5px" type="button" @click="sendcode" :disabled="disabled"
              v-if="disabled == true">{{ btntxt }}
            </el-button>
          </div>
        </el-form-item>
        <div style="text-align: center">
          <el-button type="primary" style="width: 100%; margin-top: 10px" @click="registerSubmit('registerForm')">立即注册
          </el-button>
        </div>
      </el-form>
    </div>
    <indexFooter></indexFooter>
  </div>
</template>

<script>
  import indexFooter from "@/components/indexFooter.vue"; //这里是引入请求
  import {
    sendVerificationCode,
    register,
    login
  } from "../request/api"; //这里是引入请求
  
  export default {
    data() {
      return {
        //以下为登录表单相关数据
        dataForm: {
          userName: "",
          password: "",
          userType: "22",
        },
        dataRule: {
          userName: [{
            required: true,
            message: "帐号不能为空",
            trigger: "blur"
          }, ],
          password: [{
            required: true,
            message: "密码不能为空",
            trigger: "blur"
          }, ],
          captcha: [{
            required: true,
            message: "验证码不能为空",
            trigger: "blur"
          }, ],
        },
        captchaPath: "",
        //以下为注册表单相关数据
        checkPhone: false, //电话号码是否通过
        disabled: false, //是否显示验证码
        time: 0, //时间
        btntxt: "重新发送",
        activeName: "entryPage",
        registerForm: {
          //注册表单
          sendcode: "",
          memberPhone: "",
          password: "",
          agentId: "32",
          upMemberId: "0",
        },
        registerFormRules: {
          memberPhone: [{
              required: true,
              pattern: /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/,
              message: "请输入正确的手机号",
              trigger: "blur",
            },
            {
              max: 11,
              message: "请输入11位手机号",
              trigger: "blur"
            },
          ],
          password: [{
            required: true,
            message: "密码不能为空",
            trigger: "blur"
          }, ],
          sendcode: [{
            required: true,
            message: "验证码不能为空",
            trigger: "blur"
          }, ],
        },
      };
    },
    components: {
      indexFooter,
    },
    methods: {
      //60S倒计时
      timer() {
        if (this.time > 0) {
          this.time--;
          this.btntxt = this.time + "s后重新获取";
          setTimeout(this.timer, 1000);
        } else {
          this.time = 0;
          this.btntxt = "获取验证码";
          this.disabled = false;
        }
      },
      login() {
        this.$router.push({
          path: "/page"
        });
        this.$store.commit("alterLoginFlag", true);
      },
      // 手机发送验证码
      sendcode() {
        const reg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
        if (this.registerForm.memberPhone == "") {
          this.$message({
            message: "手机号不能为空",
            center: true,
          });
          return;
        }
        if (!reg.test(this.registerForm.memberPhone)) {
          this.$message({
            message: "请输入正确的手机号",
            center: true,
          });
          return;
        } else {
          sendVerificationCode({
            memberPhone: this.registerForm.memberPhone,
          }).then((data) => {
            this.checkPhone = true;
          });
          this.time = 60;
          this.disabled = true;
          this.timer();
        }
      },
      // 为注册表单绑定验证功能
      registerSubmit(formName) {
        if (this.checkPhone) {
          //判断验证码是否已发送
          this.$refs[formName].validate((valid) => {
            //验证注册表单是否满足所有条件
            register(this.registerForm).then((data) => {
               //注册成功改变Tab的状态跳到登录页
              this.$message({
                message: "注册成功，请登录",
                type: "success",
                duration: 1500,
              });
              this.activeName = "entryPage"; //改变tab状态
            });
          });
        } else {
          this.checkPhone = false; //如果验证码不通过得话
          this.$message({
            message: "请先获取验证码",
            center: true,
          });
        }
      },
      // 提交登录表单
      dataFormSubmit() {
        this.$refs["dataForm"].validate((valid) => {
          if (valid) {
            login(this.dataForm).then((data) => {
              this.$cookies.set('token', data.token)
              this.$store.commit("alterToken", data.token);
              this.$message({
                message: "登录成功，马上跳转至个人中心",
                type: "success",
                duration: "1000"
              });
              setTimeout(() => {
                if(this.$route.path == "/personalCenter"){
                  location.reload(true);
                }else{
                  this.$router.push({
                    path: "/personalCenter"
                  });
                }
              }, 500);
            });
          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  header {
    height: 80px;
    line-height: 80px;
    background-color: #fff;
  }

  .toolTitle {
    min-width: 180px;
    font-size: 32px;
    color: #111111;
    letter-spacing: 0;
    cursor: pointer;
  }


  .registerContent {
    margin: 10px auto 68px;
    background-color: #fff;
    padding: 80px 350px 100px;
    box-sizing: border-box;
  }

  .registerTitle {
    font-size: 28px;
    color: #111111;
    letter-spacing: 0;
    font-weight: bold;
  }

  .registerQuestion {
    font-size: 14px;
    color: #999999;
    letter-spacing: 0;
    margin-bottom: 40px;

  }

  /deep/.el-input__inner {
    height: 40px;
    line-height: 40px;
  }

  /deep/.el-button--button {
    // height: 40px;
    // line-height: 40px;
  }
</style>